import { formatDate } from '@/utils/date';
import { DATETIME_FORMAT } from '@/utils/constants';

export default class POSMFormTableData {
  /**
   * @param {String} uuid
   * @param {String} name
   * @param {String} campaignName
   * @param {Array} timeRange
   * @param {String} createdBy
   * @param {Array} stores
   */
  constructor(uuid, name, campaignName, timeRange, createdBy, stores) {
    this.uuid = uuid;
    this.name = name;
    this.campaignName = campaignName;
    this.timeRange = timeRange;
    this.createdBy = createdBy;
    this.stores = stores;
  }

  formatTime() {
    this.timeRange = [
      formatDate(this.timeRange[0], DATETIME_FORMAT),
      formatDate(this.timeRange[1], DATETIME_FORMAT),
    ];
  }
}
